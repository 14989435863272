/* https://cloudconvert.com/ttf-to-woff */

/* https://www.cdnfonts.com/butler.font */
@font-face {
    font-family: 'butler';
    src: url('../fonts/butler/Butler_Webfont/Butler.woff') format('woff');
}

.font-butler {
    font-family: 'butler', sans-serif !important;
}

/* https://fonts.google.com/specimen/Cormorant+Infant?preview.text=Solsticio&stroke=Serif */
@font-face {
    font-family: 'cormorant-infant';
    src: url('../fonts/cormorant_infant/CormorantInfant-Regular.woff') format('woff');
}

.font-cormorant-infant {
    font-family: 'cormorant-infant', sans-serif !important;
}

/* https://fonts.google.com/download/next-steps?preview.text=Solsticio&stroke=Serif */
@font-face {
    font-family: 'im-fell-double-pica';
    src: url('../fonts/im_fell_double_pica/IMFellDoublePica-Regular.woff') format('woff');
}

.font-im-fell-double-pica {
    font-family: 'im-fell-double-pica', sans-serif !important;
}

/* https://fonts.google.com/specimen/IM+Fell+DW+Pica?preview.text=Solsticio&stroke=Serif */
@font-face {
    font-family: 'im-fell-dw-pica';
    src: url('../fonts/im_fell_dw_pica/IMFellDWPica-Regular.woff') format('woff');
}

.font-im-fell-dw-pica {
    font-family: 'im_fell_dw_pica', sans-serif !important;
}

/* https://fonts.google.com/specimen/Inter */
@font-face {
    font-family: 'inter';
    src: url('../fonts/inter/static/Inter-Light.woff') format('woff');
}

.font-inter {
    font-family: 'inter', sans-serif !important;
}

/* https://fonts.google.com/specimen/Lora?preview.text=Solsticio&query=lora&stroke=Serif */
@font-face {
    font-family: 'lora';
    src: url('../fonts/lora/Lora-Regular.woff') format('woff');
}

.font-lora {
    font-family: 'lora', sans-serif !important;
}
/* https://fonts.google.com/noto/specimen/Noto+Serif+Tibetan?preview.text=Solsticio&stroke=Serif */
@font-face {
    font-family: 'noto-serif-tibetan';
    src: url('../fonts/noto_serif_tibetan/NotoSerifTibetan-Regular.woff') format('woff');
}

.font-noto-serif-tibetan {
    font-family: 'noto-serif-tibetan', sans-serif !important;
}

/* https://fonts.google.com/noto/specimen/Noto+Sans */
@font-face {
    font-family: 'noto-sans';
    src: url('../fonts/noto_sans/NotoSans-Light.woff') format('woff');
}

.font-noto-sans {
    font-family: 'noto-sans', sans-serif !important;
}

/* https://fonts.google.com/specimen/Roboto */
@font-face {
    font-family: 'roboto';
    src: url('../fonts/roboto/Roboto-Thin.woff') format('woff');
}

.font-roboto {
    font-family: 'roboto', sans-serif !important;
}